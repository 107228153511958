
/**
 * @name: 公告管理-公告通知
 * @author: itmobai
 * @date: 2023-12-07 16:39
 * @description： 公告管理-公告通知
 * @update: 2023-12-07 16:39
 */
import { Vue, Component } from "vue-property-decorator"
import { noticeQueryApi, noticeCreateApi, noticeModifyApi, noticeRemoveApi, noticeDetailApi } from "@/apis/notice/list";
import { INoticeList, INoticeListParam } from "@/apis/notice/list/types";
import type { ICrudOption } from "@/types/m-ui-crud"
import {deepCopy} from "@/utils/common";

@Component({})
export default class noticeList extends Vue {
  // 表格加载状态
  tableLoading: boolean = true;
  // 表格数据
  tableData: INoticeList[] = []
  // 表格总数
  tableTotal = 0
  // 查询参数
  queryParam: INoticeListParam = {
    page: 1,
    limit: 10
  }
  // 表单参数
  modelForm: Partial<INoticeList> = {}
  // crud配置信息
  crudOption: ICrudOption = {
    addTitle: '新增公告',
    editTitle: '编辑公告',
    column: [
      {
        label: "公告ID",
        prop: "noticeId",
        align: "left",
        width: 180,
        addHide: true,
        editHide: true
      },
      {
        label: "公告内容",
        prop: "content",
        align: "center",
        search: true,
        maxlength: 50,
        overHidden: true,
        type: "textarea",
        showWordLimit: true,
        rules: [
          {required: true, message: "公告内容不能为空", trigger: "blur"}
        ]
      },
      {
        label: "经办人",
        prop: "addName",
        align: "center",
        width: 150,
        addHide: true,
        editHide: true,
        search: true
      },
      {
        label: "创建时间",
        prop: "addTime",
        type: "daterange",
        align: "center",
        width: 150,
        search: true,
        addHide: true,
        editHide: true
      }
    ]
  }

  getList () {
    this.tableLoading = true
    const query: INoticeListParam = deepCopy(this.queryParam)
    if (query.addTime && query.addTime.length) {
      query.addStartTime = query.addTime[0]
      query.addEndTime = query.addTime[1]
    } else {
      query.addStartTime = ''
      query.addEndTime = ''
    }
    delete query.addTime
    noticeQueryApi(query).then(e => {
      if (e) {
        this.tableData = e.list
        this.tableTotal = e.total
        this.tableLoading = false
      }
    })
  }

  rowSave (form: INoticeList, done: Function, loading: Function) {
    noticeCreateApi(form).then(e => {
      if (e) {
        this.$message.success("操作成功!")
        this.getList()
        done()
      }
    }).finally(() => {
      loading()
    })
  }

  rowEdit (form: INoticeList, done: Function, loading: Function) {
    noticeModifyApi(form).then(e => {
      if (e) {
        this.$message.success("操作成功!")
        this.getList()
        done()
      }
    }).finally(() => {
      loading()
    })
  }

  rowDel (form: INoticeList) {
    noticeRemoveApi(form.noticeId).then(e => {
      if (e) {
        this.$message.success("操作成功!")
        this.getList()
      }
    })
  }

  /**
   * 打开编辑
   * @param row
   * @param index
   */
  openEdit (row: INoticeList, index: number) {
    noticeDetailApi(row.noticeId).then(e => {
      if (e) {
        // @ts-ignore
        this.$refs.crudRef.rowEdit(e, index)
      }
    })
  }

  created () {
    this.getList()
  }
}
