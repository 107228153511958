/**
 * @name: 公告管理-公告通知接口文件
 * @author: itmobai
 * @date: 2023-12-07 16:39
 * @description： 公告管理-公告通知接口文件
 * @update: 2023-12-07 16:39
 */
import {get, postJ} from "@/request"
import {IPageRes} from "@/apis/page";
import {INoticeList, INoticeListParam} from "@/apis/notice/list/types";

/**
 * 公告分页查询
 * @param param 查询参数
 */
export const noticeQueryApi = (param: INoticeListParam) => get<IPageRes<INoticeList[]>>("/card/notice/query", param)
/**
 * 创建
 * @param data
 */
export const noticeCreateApi = (data: Partial<INoticeList>) => postJ("/card/notice/create", data)
/**
 * 修改
 * @param data
 */
export const noticeModifyApi = (data: Partial<INoticeList>) => postJ("/card/notice/modify", data)
/**
 * 删除
 * @param id
 */
export const noticeRemoveApi = (id: string) => get("/card/notice/remove/" + id)
/**
 * 详情
 * @param id
 */
export const noticeDetailApi = (id: string) => get<INoticeList>("/card/notice/detail/" + id)
